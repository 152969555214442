import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import validate from "./RegisterFormValidationRules";
import Checked from "../../assets/images/checked.png";
import Warning from "../../assets/images/warning.png";
import Cross from "../../assets/images/cross.png";
import BlueClose from "../../assets/images/blue-close.svg";
import axios from "axios";
import "./Register.scss";

const Register = ({ onClose }) => {
  // Form state management (from useForm)
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  
  // Existing state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [modalImage, setModalImage] = useState(Checked);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [jobDescFile, setJobDescFile] = useState(null);
  const [offerCheckboxChecked, setOfferCheckboxChecked] = useState(false);
  const [language, setLanguage] = useState("francais");
  const [jobType, setJobType] = useState('');
  const [docFormat, setDocFormat] = useState('');
  const [deliverables, setDeliverables] = useState({
    cvOriginal: false,
    cvVersionIA: false,
    scoringAppelOffre: false,
    guideOral: false,
    questionsEntretiens: false,
    testerCandidat: false
  });
  const [isDeliverablesEnabled, setIsDeliverablesEnabled] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [jobDescDragActive, setJobDescDragActive] = useState(false);
  const fileInputRef = useRef(null);
  const jobDescFileInputRef = useRef(null);

  // Handlers from useForm
  const handleCompanyCode = async (companyCode) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ companyCode: companyCode }),
      };

      const response = await fetch(
        process.env.REACT_APP_COMPANY_DETAILS_API_URL,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Failed to fetch company details");
      }

      const data = await response.json();
      const { companyName, aiCV, oralGuidelines, originalCV, qAndA, scoring, docFormat } = data;

      if (companyName !== "unknown") {
        // Create an object to track which checkboxes should be enabled
        const enabledDeliverables = {
          cvOriginal: originalCV === 1,
          cvVersionIA: aiCV === 1,
          scoringAppelOffre: scoring === 1,
          guideOral: oralGuidelines === 1,
          questionsEntretiens: qAndA === 1,
          testerCandidat: false
        };

        // Update the disabled state of each checkbox
        setIsDeliverablesEnabled(enabledDeliverables);

        setDocFormat(docFormat);
        // Keep the checked state as false for all checkboxes
        setDeliverables({
          cvOriginal: false,
          cvVersionIA: false,
          scoringAppelOffre: false,
          guideOral: false,
          questionsEntretiens: false,
          testerCandidat: false
        });
      }

      setValues((prevValues) => ({
        ...prevValues,
        companyName: companyName,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;

    setValues({
      ...values,
      [name]: fieldValue,
    });

    if (name === "companyCode") {
      setTimeout(() => {
        handleCompanyCode(value);
      }, 100);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      handleSubmitForm();
    }
  };

  // Existing handlers
  const handleFileRemove = (fileToRemove) => {
    setFiles(files.filter((file) => file !== fileToRemove));
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleJobDescFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setJobDescFile(file);
      setFileUploaded(true);
    } else {
      setJobDescFile(null);
      setFileUploaded(false);
    }
  };

  const handleDeliverableChange = (e) => {
    const { name, checked } = e.target;
    setDeliverables(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    if (name === "offerCheckbox") {
      setOfferCheckboxChecked(checked);
      if (!checked) {
        setJobDescFile(null);
      }
    }
    handleChange(e);
  };

  const handleSubmitForm = async () => {
    setIsLoading(true);
    try {
      const requests = files.map((file) => {
        const formData = new FormData();
        formData.append("companyCode", values.companyCode);
        formData.append("cvFile", file);
        if (jobDescFile) {
          formData.append("jobDescFile", jobDescFile);
        }
        formData.append("docFormat", docFormat);
        formData.append("jobType", jobType);
        formData.append("language", language);
        
        // Add all deliverables values
        Object.entries(deliverables).forEach(([key, value]) => {
          formData.append(key, value);
        });

        return axios.post(
          process.env.REACT_APP_SUBMIT_API_URL,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
      });

      const responses = await Promise.allSettled(requests);
      const successCount = responses.filter(
        (res) => res.status === "fulfilled" && res.value.status === 200
      ).length;

      setModalMessage(
        `${successCount} fichier(s) soumis avec succès. ${responses.length - successCount} fichier(s) échoué(s).`
      );
      setSubmitSuccess(true);
      setModalImage(successCount > 0 ? Checked : Warning);
    } catch (error) {
      console.error("Error during file submissions:", error);
      setModalMessage(
        "Une erreur s'est produite lors de la soumission. Veuillez réessayer."
      );
      setModalImage(Warning);
    } finally {
      setIsLoading(false);
      setIsModalOpen(true);
      setTimeout(() => {
        setIsModalOpen(false);
        onClose();
      }, 2000);
    }
  };

  // Drag and drop handlers for CV files
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const droppedFiles = Array.from(e.dataTransfer.files);
      setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // Drag and drop handlers for job description file
  const handleJobDescDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!offerCheckboxChecked) return;
    
    if (e.type === "dragenter" || e.type === "dragover") {
      setJobDescDragActive(true);
    } else if (e.type === "dragleave") {
      setJobDescDragActive(false);
    }
  };

  const handleJobDescDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setJobDescDragActive(false);
    if (!offerCheckboxChecked) return;
    
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      setJobDescFile(file);
      setFileUploaded(true);
    }
  };

  const handleJobDescButtonClick = () => {
    if (offerCheckboxChecked) {
      jobDescFileInputRef.current.click();
    }
  };

  return (
    <>
      {!submitSuccess && (
        <div className="section is-fullheight">
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="title">Déposer un CV</h1>
                <p>
                  Ce questionnaire a pour objectif de récolter quelques informations pour nous permettre de créer ton CV.
                </p>
                <button className="close-icon" onClick={onClose}>
                  <img src={Cross} alt="cross" />
                </button>
                <div className="box">
                  <form onSubmit={handleSubmit} noValidate>
                    <div className="field checkbox-wrap">
                      <div className="control">
                        <input
                          type="checkbox"
                          id="checkbox1"
                          name="checkbox1"
                          checked={values.checkbox1 || false}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="checkbox1">Code de la société</label>
                      </div>
                      {errors.checkbox1 && (
                        <p className="help is-danger">{errors.checkbox1}</p>
                      )}
                    </div>
                    <div className="field-group">
                      <div className="field">
                        <div className="control">
                          <label>Code de la société</label>
                          <input
                            className={`input ${
                              errors.companyCode && "is-danger"
                            }`}
                            type="text"
                            name="companyCode"
                            disabled={!values.checkbox1}
                            onChange={handleChange}
                            value={values.companyCode || ""}
                            placeholder=""
                          />
                        </div>
                        {errors.companyCode && (
                          <p className="help is-danger">{errors.companyCode}</p>
                        )}
                      </div>
                      <div className="field">
                        <div className="control">
                          <label>Nom de la société</label>
                          <input
                            className="input"
                            type="text"
                            disabled={!values.checkbox1}
                            name="companyName"
                            value={values.companyName || ""}
                            readOnly
                            placeholder=""
                          />
                        </div>
                      </div>
                    </div>
                    
                    <div className="field file-upload section-divider">
                      <div
                        className="control"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        Télécharger les CVs
                      </div>
                      
                      <div 
                        className={`drag-drop-area ${dragActive ? "drag-active" : ""}`}
                        onDragEnter={handleDrag}
                        onDragOver={handleDrag}
                        onDragLeave={handleDrag}
                        onDrop={handleDrop}
                        onClick={handleButtonClick}
                      >
                        <input
                          ref={fileInputRef}
                          id="cvFile"
                          name="cvFile"
                          type="file"
                          accept=".pdf,.docx"
                          onChange={handleFileChange}
                          multiple
                          style={{ display: "none" }}
                        />
                        <div className="drag-drop-content">
                          <i className="fa-solid fa-cloud-arrow-up"></i>
                          <p>{files.length > 0
                            ? `${files.length} fichier(s) sélectionné(s)`
                            : "Glissez et déposez vos fichiers ici ou cliquez pour parcourir"}</p>
                          <span className="file-types">Formats acceptés: PDF, DOCX</span>
                        </div>
                      </div>
                      
                      {files.length > 0 && (
                        <div className="selected-files">
                          {files.map((file, index) => (
                            <div key={index} className="file-item">
                              <p>{file.name}</p>
                              <img
                                src={BlueClose}
                                alt="Remove"
                                onClick={() => handleFileRemove(file)}
                                style={{ marginLeft: "10px", cursor: "pointer" }}
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      
                      {errors.cvFile && (
                        <p className="help is-danger">{errors.cvFile}</p>
                      )}
                    </div>
                    <div className="field-group">
                      <div className="field">
                        <label htmlFor="language" className="field-label">Langue</label>
                        <div className="select-wrapper">
                          <select
                            id="language"
                            value={language || "francais"}
                            onChange={(e) => setLanguage(e.target.value)}
                            className="select-input"
                          >
                            <option value="" disabled>Sélectionner une langue</option>
                            <option value="francais">Français</option>
                            <option value="anglais">Anglais</option>
                          </select>
                        </div>
                      </div>

                      <div className="field">
                        <label htmlFor="jobType" className="field-label">Type de poste</label>
                        <div className="select-wrapper">
                          <select
                            id="jobType"
                            value={jobType}
                            onChange={(e) => setJobType(e.target.value)}
                            className="select-input"
                          >
                            <option value="" disabled>Sélectionner un type de poste</option>
                            <option value="moa">MOA / Business Analyst</option>
                            <option value="chef-de-projet">Chef de Projet</option>
                            <option value="product-owner">Product Owner / Scrum Master</option>
                            <option value="consultant-fonctionnel">Consultant Fonctionnel</option>
                            <option value="consultant-technique">Consultant Technique / Développeur</option>
                            <option value="architecte-si">Architecte SI / Consultant Data</option>
                            <option value="expert-securite">Expert Sécurité / DevOps</option>
                            <option value="pmo">PMO (Project Management Officer)</option>
                            <option value="finance-comptabilite">Finance & Comptabilité</option>
                            <option value="rh-paie">Ressources Humaines & Paie</option>
                            <option value="supply-chain">Supply Chain & Logistique</option>
                            <option value="marketing-commercial">Marketing & Commercial</option>
                            <option value="gestion-risques">Gestion des Risques & Conformité</option>
                            <option value="juridique-fiscalite">Juridique & Fiscalité</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="field checkbox-wrap section-divider">
                      <div className="control">
                        <input
                          type="checkbox"
                          id="offerCheckbox"
                          name="offerCheckbox"
                          checked={offerCheckboxChecked}
                          onChange={handleCheckboxChange}
                        />
                        <label htmlFor="offerCheckbox">
                          Je dispose d'un appel d'offres pour la mission
                        </label>
                      </div>
                    </div>

                    <div className="field file-upload">
                      <div
                          className="control"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          Télécharger les fichiers
                      </div>
                      
                      <div 
                        className={`drag-drop-area ${jobDescDragActive ? "drag-active" : ""} ${!offerCheckboxChecked ? "disabled" : ""}`}
                        onDragEnter={handleJobDescDrag}
                        onDragOver={handleJobDescDrag}
                        onDragLeave={handleJobDescDrag}
                        onDrop={handleJobDescDrop}
                        onClick={handleJobDescButtonClick}
                      >
                        <input
                          ref={jobDescFileInputRef}
                          id="jobDescFile"
                          name="jobDescFile"
                          type="file"
                          accept=".pdf,.docx"
                          onChange={handleJobDescFileChange}
                          disabled={!offerCheckboxChecked}
                          style={{ display: "none" }}
                        />
                        <div className="drag-drop-content">
                          <i className="fa-solid fa-cloud-arrow-up"></i>
                          <p>{jobDescFile
                            ? jobDescFile.name
                            : offerCheckboxChecked 
                              ? "Glissez et déposez votre fichier ici ou cliquez pour parcourir" 
                              : "Veuillez cocher la case ci-dessus pour activer le téléchargement"}</p>
                          <span className="file-types">Formats acceptés: PDF, DOCX</span>
                        </div>
                      </div>
                      
                      {jobDescFile && (
                        <div className="selected-files">
                          <div className="file-item">
                            <p>{jobDescFile.name}</p>
                            <img
                              src={BlueClose}
                              alt="Remove"
                              onClick={() => {
                                setJobDescFile(null);
                                setFileUploaded(false);
                              }}
                              style={{ marginLeft: "10px", cursor: "pointer" }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="field section-divider">
                      <h2 className="subtitle">Livrables souhaités :</h2>
                      <div className="checkbox-group">
                        <div className="checkbox-wrap">
                          <input
                            type="checkbox"
                            id="cvOriginal"
                            name="cvOriginal"
                            checked={deliverables.cvOriginal}
                            onChange={handleDeliverableChange}
                            disabled={!isDeliverablesEnabled.cvOriginal}
                          />
                          <label htmlFor="cvOriginal">CV Original</label>
                        </div>
                        <div className="checkbox-wrap">
                          <input
                            type="checkbox"
                            id="cvVersionIA"
                            name="cvVersionIA"
                            checked={deliverables.cvVersionIA}
                            onChange={handleDeliverableChange}
                            disabled={!isDeliverablesEnabled.cvVersionIA}
                          />
                          <label htmlFor="cvVersionIA">CV version IA</label>
                        </div>
                        <div className="checkbox-wrap">
                          <input
                            type="checkbox"
                            id="scoringAppelOffre"
                            name="scoringAppelOffre"
                            checked={deliverables.scoringAppelOffre}
                            onChange={handleDeliverableChange}
                            disabled={!fileUploaded || !offerCheckboxChecked}
                          />
                          <label htmlFor="scoringAppelOffre">Scoring avec l'appel d'offre</label>
                        </div>
                        <div className="checkbox-wrap">
                          <input
                            type="checkbox"
                            id="guideOral"
                            name="guideOral"
                            checked={deliverables.guideOral}
                            onChange={handleDeliverableChange}
                            disabled={!fileUploaded || !offerCheckboxChecked}
                          />
                          <label htmlFor="guideOral">Guide oral</label>
                        </div>
                        <div className="checkbox-wrap">
                          <input
                            type="checkbox"
                            id="questionsEntretiens"
                            name="questionsEntretiens"
                            checked={deliverables.questionsEntretiens}
                            onChange={handleDeliverableChange}
                            disabled={!fileUploaded || !offerCheckboxChecked}
                          />
                          <label htmlFor="questionsEntretiens">Questions entretiens</label>
                        </div>
                        <div className="checkbox-wrap">
                          <input
                            type="checkbox"
                            id="testerCandidat"
                            name="testerCandidat"
                            checked={deliverables.testerCandidat}
                            onChange={handleDeliverableChange}
                            disabled={!isDeliverablesEnabled.testerCandidat}
                          />
                          <label htmlFor="testerCandidat">Tester mon candidat</label>
                        </div>
                      </div>
                    </div>
                    
                    <div className="field checkbox-wrap">
                      <div className="control">
                        <input
                          type="checkbox"
                          id="checkbox3"
                          name="checkbox3"
                          onChange={handleCheckboxChange}
                          required
                        />
                        <label htmlFor="checkbox3">
                          J'accepte les<Link to="/">conditions générales</Link>d'ASAP-CV
                          ainsi que la
                          <a
                            href="/politique-de-confidentialité.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            politique de protection des données
                          </a>
                        </label>
                      </div>
                      {errors.checkbox3 && (
                        <p className="help is-danger">{errors.checkbox3}</p>
                      )}
                    </div>

                    <button
                      type="submit"
                      className="button is-block is-info is-fullwidth"
                    >
                      {isLoading
                        ? "Envoi en cours..."
                        : "Envoyer les fichiers"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div className={`submitmodal ${isModalOpen ? "is-active" : ""}`}>
          <div className="modal-background" onClick={onClose}></div>
          <div className="modal-content">
            <div className="box">
              <img src={modalImage} alt="" />
              <p>{modalMessage}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Register;
